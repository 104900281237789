import React, { useState } from "react";
import { Button } from "react-bootstrap";

const Action = () => {
  const [cards, setCards] = useState([
    {
      id: 1,
      imgSrc1: require('../../assets/images/action1.png'),
      imgSrc2: require('../../assets/images/action2.png'),
      auctionTitle: 'Auction',
      time: '05:44:36',
      artistImgSrc: require('../../assets/images/artist.png'),
      artistName: 'Abstract girl',
      bidInfo: '0.08 ETH 1/20',
      placeBidLink: '/collectionsdetails',
      svgValue: 50,
    },
    
    // Add more card objects as needed
  ]);

  return (
    <>
      <section className="actions">
        <div className="container">
          <div className="row">
            <div className="col-md-6 main-heading">
              <h3>Live Actions</h3>
            </div>
            <div className="col-md-6 text-end">
              <a href="/collections" className="View_all">View all</a>
            </div>
          </div>
          <div className="row mt-4">
            {cards.map((card) => (
              <div className="col-lg-3 col-md-6 col-sm-12" key={card.id}>
                <div className="action_card">
                  <div className="single-card-img">
                    <img src={card.imgSrc1} alt="action1" className="img-fluid card-effact" />
                    <img src={card.imgSrc2} alt="action1" className="img-fluid card-effact2" />
                    <div className="img-caption">
                      <p>{card.auctionTitle}</p>
                      <h3>{card.time}</h3>
                    </div>
                  </div>
                  <div className="card-caption">
                    <img src={card.artistImgSrc} alt="action1" className="img-fluid" />
                    <h4>{card.artistName}</h4>
                    <p>{card.bidInfo}</p>
                    <ul>
                      <li>
                        <a href={card.placeBidLink}>Place Bid</a>
                      </li>
                      <li>
                       
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <g clip-path="url(#clip0_481_787)">
                          <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_481_787">
                          <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                          </clipPath>
                          </defs>
                          </svg>
                        {card.svgValue}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Action;
