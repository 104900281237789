import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';


const LevelModal = ({BtnName}) => {

   
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedImage, setSelectedImage] = useState();
    // This function will be triggered when the file field change
    const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
    setSelectedImage(e.target.files[0]);
    }
    };
    // This function will be triggered when the "Remove This Image" button is clicked
    const removeSelectedImage = () => {
    setSelectedImage();
    };
    
    
    const [fields, setFields] = useState([
        { type: 'Character', name: 'Male' }, // Initial field
      ]);
      
      const handleFieldChange = (index, key, value) => {
        const updatedFields = [...fields];
        updatedFields[index][key] = value;
        setFields(updatedFields);
      };
      const handleSubmit = (event) => {
        event.preventDefault();
      };
      // console.log(handleFieldChange)
      const handleAddMore = () => {
        const newField = { type: '', name: '' }; 
        setFields([...fields, newField]);
      };
      const removeHandleAddMore = (index) => {
        const updatedFields = [...fields];
        updatedFields.splice(index, 1); 
        setFields(updatedFields); 
      };
return (
<>
<Button variant="primary" onClick={handleShow}>+</Button>
<Modal show={show} onHide={handleClose} className="common_modal level-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add Levels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <p>Level show up underneath your item, are clickable, and can be filtered in your collection’s sidebar.</p>
              <Form onSubmit={handleSubmit}>
                {fields.map((field, index) => (
                  <div key={index}>
                    <Row>
                      <Col>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          placeholder="Speed"
                          value={field.type}
                          onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
                        />
                      </Col>
                      <Col>
                        <Form.Label>Value</Form.Label>
                        <div className="d-flex align-items-center addRemove">
                        <Form.Control
                          type="number"
                          placeholder="3"
                          value={field.name}
                          onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                        />
                        <p>of</p>
                        <Form.Control
                          type="number"
                          placeholder="3"
                          value={field.name}
                          onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                        />
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row>
                  <Col>
                    <a href="#" onClick={handleAddMore}>Add More</a>
                    {
                    (fields.length > 1) && <button className="btn btn-outline-danger" onClick={removeHandleAddMore}>Remove</button>
                  }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit" className="save">Save</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
</>
);
};
export default LevelModal;