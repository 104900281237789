import React, { useState } from "react";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Table from 'react-bootstrap/Table';
import { Button } from "react-bootstrap";

const settings = {
  arrows:true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  // centerMode: true,
  // variableWidth: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,

      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        centerPadding: '40px',

      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      },
    },
  ],
};

const TextopArtist = () => {
   // State to keep track of the active tab index
   const [activeTabIndex, setActiveTabIndex] = useState(0);

   // Event handler for when a tab is selected
   const handleTabSelect = (index) => {
     setActiveTabIndex(index);
   };
  return (
    <>
    <section className="artistSlider">
      <div className="container">
        <div className="row">
          <div className="col-md-12 main-heading">
            <h3>Top Artist</h3>
          </div>
        </div>
        <Slider {...settings}>
          <div>
            <div className="card-slider">
              <div className="artist_data">
                  <img src={require("../../assets/images/artist.png")} alt="artist" className="img-fluid"/>
                  <span>Kareena Saim</span>
              </div>
            </div>
          </div>
          <div>
          <div className="card-slider">
              <div className="artist_data">
                  <img src={require("../../assets/images/artist-2.png")} alt="artist-2" className="img-fluid"/>
                  <span>Kareena Saim</span>
              </div>
            </div>
          </div>
          <div>
          <div className="card-slider">
              <div className="artist_data">
                  <img src={require("../../assets/images/artist.png")} alt="artist" className="img-fluid"/>
                  <span>Kareena Saim</span>
              </div>
            </div>
          </div>
          <div>
          <div className="card-slider">
            <div className="artist_data">
                  <img src={require("../../assets/images/artist-2.png")} alt="artist" className="img-fluid"/>
                  <span>Kareena Saim</span>
              </div>
            </div>
          </div>
          <div>
          <div className="card-slider">
            <div className="artist_data">
                  <img src={require("../../assets/images/artist.png")} alt="artist" className="img-fluid"/>
                  <span>Kareena Saim</span>
              </div>
            </div>
            
          </div>
          <div>
            <div className="card-slider">
              <div className="artist_data">
                  <img src={require("../../assets/images/artist-2.png")} alt="artist" className="img-fluid"/>
                  <span>Kareena Saim</span>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      </section>

      {/* ----------------------collections------------------- */}
      <section className="collection pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="collection-list">
                <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect}>
                  <div className="row">
                    <div className="col-md-6">
                      <TabList>
                      <Tab className="untabactive">Collections</Tab>
                      <Tab className="tabactive">Marketplaces</Tab>
                    </TabList>
                    </div>
                    <div className="col-md-6">
                      <div className="day-data text-end">
                        <ul>
                          <li className="active"><Button>1 hr</Button></li>
                          <li><Button>6 hr</Button></li>
                          <li className="border-right"><Button>3 hr</Button></li>
                          <li><a href="/collections">View all</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  

                  <TabPanel>
                    <div className="table_data">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Rank</th>
                            <th>Collection</th>
                            <th>Base Price</th>
                            <th>Volume</th>


                            <th className="table_head">Rank</th>
                            <th>Collection</th>
                            <th>Base Price</th>
                            <th>Volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td><span><img src={require("../../assets/images/table_img.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>


                            <td className="table_head">2</td>
                            <td><span><img src={require("../../assets/images/table_img2.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td><span><img src={require("../../assets/images/table_img.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>


                            <td className="table_head">4</td>
                            <td><span><img src={require("../../assets/images/table_img3.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td><span><img src={require("../../assets/images/table_img4.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>

                            <td className="table_head">6</td>
                            <td><span><img src={require("../../assets/images/table_img2.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td><span><img src={require("../../assets/images/table_img.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>

                            <td className="table_head">8</td>
                            <td><span><img src={require("../../assets/images/table_img3.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td><span><img src={require("../../assets/images/table_img.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                            

                            <td className="table_head">10</td>
                            <td><span><img src={require("../../assets/images/table_img4.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </TabPanel>

                  <TabPanel>
                  <div className="table_data">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Rank</th>
                            <th>Collection</th>
                            <th>Base Price</th>
                            <th>Volume</th>


                            <th className="table_head">Rank</th>
                            <th>Collection</th>
                            <th>Base Price</th>
                            <th>Volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td><span><img src={require("../../assets/images/table_img2.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>


                            <td className="table_head">2</td>
                            <td><span><img src={require("../../assets/images/table_img.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td><span><img src={require("../../assets/images/table_img3.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>


                            <td className="table_head">4</td>
                            <td><span><img src={require("../../assets/images/table_img4.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td><span><img src={require("../../assets/images/table_img.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>

                            <td className="table_head">6</td>
                            <td><span><img src={require("../../assets/images/table_img2.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td><span><img src={require("../../assets/images/table_img3.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>

                            <td className="table_head">8</td>
                            <td><span><img src={require("../../assets/images/table_img4.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td><span><img src={require("../../assets/images/table_img.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                            

                            <td className="table_head">10</td>
                            <td><span><img src={require("../../assets/images/table_img4.png")} alt="artist" className="img-fluid"/>Roker MKT</span></td>
                            <td>0.01 ETH</td>
                            <td>1.6 ETH</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default TextopArtist;
