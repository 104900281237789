import React, { useState } from "react";
// import "../components/banner.css";
import  Carousel  from "react-bootstrap/Carousel";
import bannerImage from '../../assets/images/blocktech_nft.png'

const Banner = () => {
  const images = {
    sliderImages: [
      require("../../assets/images/blocktech_nft.png").default,
      require("../../assets/images/blocktech_nft.png").default,
      require("../../assets/images/blocktech_nft.png").default,
      // Add more image URLs here as needed
    ],
  };
  const caption = {
    title: "Create, sell and collect digital items.",
    description:
      "Unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable.",
    collectibles: "94215",
    auctions: "27k",
    nftArtists: "4k",
    viewArtworkLink: "/explore", // Set the link for "View artwork" button
  };
    return (
    <>
      <section className="banner">
        <img src={require("../../assets/images/banner-bg.png")} alt="Banner Background"
          className="img-fluid banner-bg"
        />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
             
                  <div className="row">
                    <div className="col-md-6">
                    <Carousel>
                    {images.sliderImages.map((img, index) => (
                      <Carousel.Item key={index}>
                        <div className="slider-img">
                          <img src={bannerImage} alt={`Slider ${index + 1}`} className="img-fluid" />
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                    </div>
                    <div className="col-md-6">
                    <Carousel.Caption className="mt-5">
                    <h3>{caption.title}</h3>
                    <p>{caption.description}</p>
                    {/* <ul>
                      <li>
                        <h4>{caption.collectibles}</h4>
                        <span>Collectibles</span>
                      </li>
                      <li>
                        <h4>{caption.auctions}</h4>
                        <span>Auctions</span>
                      </li>
                      <li>
                        <h4>{caption.nftArtists}</h4>
                        <span>NFT Artist</span>
                      </li>
                    </ul> */}
                    <ul>
                      <li>
                        <a href="/collectionsdetails" className="place_btn">
                          Place Bid
                        </a>
                      </li>
                      <li>
                        <a href={caption.viewArtworkLink} className="view_btn">
                          View artwork
                        </a>
                      </li>
                    </ul>
                  </Carousel.Caption>
                    </div>
                  </div>
               
              
            </div>
          </div>
        </div>
      </section>
    </>
  );

    };
export default Banner;
