import React from "react";
import "./LandingPage.css";
import Banner from "../home/banner";
import CreateCell from "./createCell";
import ProductItems from "./ProductItems";
import TextopArtist from "./topArtist";
import Action from "./action";
import PopularCollections from "./PopularCollections";
import Categories from "./Categories";
const LandingPage = () => {
  return (
    <>
     <Banner/>
      <CreateCell/>
      <ProductItems/>
      <TextopArtist/>
      <Action/>
      <PopularCollections/>
      <Categories/>
    </>
  );
};

export default LandingPage;
