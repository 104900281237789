import React, { useState, useEffect } from "react";
import "./createnft.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import LevelModal from "../../components/NFT/level-modal/levelsModal";
import StatsModal from "./stats-modal/statsModal";
import axios from "axios";
// import { Formik, useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASE_URL } from "../../Utility/Urls";

const Createnft = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedImage, setSelectedImage] = useState();

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  
  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const [fields, setFields] = useState([
    { type: "Character", name: "Male" }, // Initial field
  ]);

  const handleFieldChange = (index, key, value) => {
    const updatedFields = [...fields];
    updatedFields[index][key] = value;
    setFields(updatedFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  // console.log(handleFieldChange)
  const handleAddMore = () => {
    const newField = { type: "", name: "" };
    setFields([...fields, newField]);
  };
  const removeHandleAddMore = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };
  const [formData, setFormData] = useState({
    image: null,
    name: "",
    externalLink: "",
    description: "",
    collection: "",
    unlockableContent: false,
    explicitContent: false,
    supply: 1,
    blockchain: "Ethereum",
  });
  //  const handleInputChange = (event) => {
  //   const { name, value, type, checked } = event.target;

  //   const updatedValue = type === 'checkbox' ? checked : value;

  //   setFormData((prevData) => ({
  //      ...prevData,
  //      [name]: updatedValue,
  //   }));
  // };
  const [handleInputName, sethandleInputName] = useState("");
  const [externalLink, setExternalLink] = useState("");
  const [description, setDescription] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [supply, setSupply] = useState(1);
  const [iSwitchOn, setSwitchOn] = useState(false);
  const [blockchain, setBlockchain] = useState("Ethereum");

  const subform = async (event) => {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem("user-info"));

    console.log("token", token);

    try {
      const responseqw = await axios.post(
        `${API_BASE_URL}/User/assets`,
        {
          content_senstity: 0,
          content_lockable: 0,
          blockchain: 0,

          price: 0,
          is_auction: 0,
          quantity: 0,
          // token_id: token,
          tokenaddress: "",
          external_url: "",
          description: "",
          shortdescription: "",
          collection_id: "64d63bef87dc28633cb5acbe",
          category_id: "64d63c1fa73e648ff4a4c509",
          title: "test",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (responseqw.status === 200) {
        const asset_id = responseqw.data.data.saveResponse._id;

        const responseproperties = await axios.post(
          `${API_BASE_URL}User/properties`,
          {
            name: "string",
            type: "string",
            asset_id: asset_id,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        // Display success toast after the second axios.post
        toast("Profile data saved successfully!");

        console.log(responseproperties, "toasty");
      }
    } catch (error) {
      console.error("API Error:", error);

      // Display error toast for API errors
      toast.error("An error occurred. Please try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <section className="nftbanner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3>Create New Item</h3>
            </div>
          </div>
        </div>
      </section>
      <div className="nft-form">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Form onSubmit={subform}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Text className="text-muted">
                    <span>*</span>
                    <p>Required fields</p>
                  </Form.Text>
                  <Form.Text>
                    <h5>Image, Video, Audio, or 3D Model *</h5>
                    <p>
                      File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3,
                      WAV, OGG, GLB, GLTF. Max size: 100 MB
                    </p>
                  </Form.Text>
                  <div className="position-relative">
                    <div className="choose_img">
                      <input
                        accept="image/*"
                        type="file"
                        onChange={imageChange}
                      />
                      <img
                        src={require("../../assets/images/mdi_image.png")}
                        alt="item-icon"
                        className="img-fluid"
                      />
                    </div>

                    {selectedImage && (
                      <div className="preview_img">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="Thumb"
                        />
                        <button onClick={removeSelectedImage}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                          >
                            <path
                              d="M28.8327 5.1665C22.3327 -1.3335 11.666 -1.3335 5.16602 5.1665C-1.33398 11.6665 -1.33398 22.3332 5.16602 28.8332C11.666 35.3332 22.166 35.3332 28.666 28.8332C35.166 22.3332 35.3327 11.6665 28.8327 5.1665ZM21.666 23.9998L16.9993 19.3332L12.3327 23.9998L9.99935 21.6665L14.666 16.9998L9.99935 12.3332L12.3327 9.99984L16.9993 14.6665L21.666 9.99984L23.9993 12.3332L19.3327 16.9998L23.9993 21.6665L21.666 23.9998Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Item name"
                    value={formData.name}
                    onChange={(e) => sethandleInputName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>External link</Form.Label>
                  <p>
                    Crypto.media will include a link to this URL on this item's
                    detail page, so that users can click to learn more about it.
                    You are welcome to link to your own webpage with more
                    details.
                  </p>
                  <Form.Control
                    type="text"
                    placeholder="URL"
                    value={externalLink}
                    onChange={(e) => setExternalLink(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <p>
                    The description will be included on the item's detail page
                    underneath its image. Markdown syntax is supported.
                  </p>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Provide a detailed description of your item."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Collection</Form.Label>
                  <p>This is the collection where your item will appear.</p>
                  <Button variant="primary" onClick={handleShow2} className="create_collectionbtn">
                    Create a collection
                  </Button>
                  {/* <Form.Control type="text" placeholder="Select collection" /> */}
                </Form.Group>
                <hr/>
                <Form.Group className="mb-3">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="listing">
                        <div className="list_img">
                          <img
                            src={require("../../assets/images/item-icon.png")}
                            alt="item-icon"
                            className="img-fluid"
                          />
                        </div>
                        <div className="list_text">
                          <h4>Properties</h4>
                          <p>Textual traits that show up as rectangles</p>


                          <div className="level_data">
                          <ul>
                          {
                            fields.map((value, index) => {
                              return <>
                                 <li>{value.type} - {value.name} </li>
                              </>
                            })
                          }
                          </ul>
                        </div>
                        </div>
                        
                      </div>
                    </div>
                    <div className="col-md-2">
                      <ul className="pop-btn">
                        <li>
                          <Button variant="primary" onClick={handleShow}>
                            +
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="listing">
                        <div className="list_img">
                          <img
                            src={require("../../assets/images/star.png")}
                            alt="star"
                            className="img-fluid"
                          />
                        </div>
                        <div className="list_text">
                          <h4>Levels</h4>
                          <p>Numerical traits that show as a progress bar</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <ul className="pop-btn">
                        <li>
                          <LevelModal />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="listing">
                        <div className="list_img">
                          <img
                            src={require("../../assets/images/bx_stats.png")}
                            alt="star"
                            className="img-fluid"
                          />
                        </div>
                        <div className="list_text">
                          <h4>Stats</h4>
                          <p>Numerical traits that just show as numbers</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <ul className="pop-btn">
                        <li>
                          <StatsModal />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="listing">
                        <div className="list_img">
                          <img
                            src={require("../../assets/images/ic_baseline-lock.png")}
                            alt="star"
                            className="img-fluid"
                          />
                        </div>
                        <div className="list_text">
                          <h4>Unlockable Content</h4>
                          <p>
                            Include unlockable content that can only be revealed
                            by the owner of the item.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        // label="Toggle Switch"
                        checked={isSwitchOn}
                        onChange={() => setIsSwitchOn(!isSwitchOn)}
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="listing">
                        <div className="list_img">
                          <img
                            src={require("../../assets/images/mingcute_alert-fill.png")}
                            alt="star"
                            className="img-fluid"
                          />
                        </div>
                        <div className="list_text">
                          <h4>Explicit & Sensitive Content</h4>
                          <p>Set this item as explicit and sensitive content</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        // label="Toggle Switch"
                        checked={iSwitchOn} // Set the checked state based on your data/condition
                        onChange={() => setSwitchOn(!iSwitchOn)}
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Supply</Form.Label>
                  <p>
                    The number of items that can be minted. No gas cost to you!
                  </p>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    value={supply}
                    onChange={(e) => setSupply(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3 coin">
                  <Form.Label>Blockchain</Form.Label>
                  <p>
                    The number of items that can be minted. No gas cost to you!
                  </p>
                  <Form.Control
                    type="text"
                    placeholder="Ethereum"
                    value={blockchain}
                    onChange={(e) => setBlockchain(e.target.value)}
                  />
                  <img
                    src={require("../../assets/images/ethereum.svg.png")}
                    alt="star"
                    className="img-fluid"
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="create_btn"
                  onClick={subform} // Attach the onClick event handler
                >
                  Create
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="common_modal">
        <Modal.Header closeButton>
          <Modal.Title>Add Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <p>
                Properties show up underneath your item, are clickable, and can
                be filtered in your collection’s sidebar.
              </p>
              <Form onSubmit={handleSubmit}>
                {fields.map((field, index) => (
                  <div key={index}>
                    <Row>
                      <Col>
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                          placeholder="Character"
                          value={field.type}
                          onChange={(e) =>
                            handleFieldChange(index, "type", e.target.value)
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Male"
                          value={field.name}
                          onChange={(e) =>
                            handleFieldChange(index, "name", e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row>
                  <Col>
                    <a href="#" onClick={handleAddMore}>
                      Add More
                    </a>
                    {fields.length > 1 && (
                      <button
                        className="btn btn-outline-danger"
                        onClick={removeHandleAddMore}
                      >
                        Remove
                      </button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      type="submit"
                      className="create_btn"
                      onClick={subform} // Attach the onClick event handler
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* create collection */}
      <Modal show={show2} onHide={handleClose2} className="create_collection common_modal">
        <Modal.Header closeButton className="d-block">
          <Modal.Title>Create a collection</Modal.Title>
          <p>Deploying your own contract requires uploading your metadata outside of OpenSea.</p>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit="">
                <Form.Group className="mb-3">
                  <Form.Label>Logo image</Form.Label>
                  <div className="position-relative">
                    <div className="choose_img">
                      <input
                        accept="image/*"
                        type="file"
                        onChange={imageChange}
                      />
                      <img
                        src={require("../../assets/images/mdi_image.png")}
                        alt="item-icon"
                        className="img-fluid"
                      />
                    </div>

                    {selectedImage && (
                      <div className="preview_img">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="Thumb"
                        />
                        <button onClick={removeSelectedImage}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                          >
                            <path
                              d="M28.8327 5.1665C22.3327 -1.3335 11.666 -1.3335 5.16602 5.1665C-1.33398 11.6665 -1.33398 22.3332 5.16602 28.8332C11.666 35.3332 22.166 35.3332 28.666 28.8332C35.166 22.3332 35.3327 11.6665 28.8327 5.1665ZM21.666 23.9998L16.9993 19.3332L12.3327 23.9998L9.99935 21.6665L14.666 16.9998L9.99935 12.3332L12.3327 9.99984L16.9993 14.6665L21.666 9.99984L23.9993 12.3332L19.3327 16.9998L23.9993 21.6665L21.666 23.9998Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="My Collection Name"
                    value=""
                    onChange=""
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Blockchain</Form.Label>
                  <Form.Select aria-label="">
                    <option>Ethereum</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-5 mt-5">
                <Button
                  variant="primary"
                  type="submit"
                  className="create_btn"
                  onClick=""// Attach the onClick event handler
                >
                  Create
                </Button>
                </Form.Group>
          </Form>
                  
        </Modal.Body>
        
      </Modal>
    </>
  );
};
export default Createnft;
