import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
export const Collectionsmodal = ({BtnName}) => {
  console.log(BtnName)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [shows, setShows] = useState(false); // Corrected the state name here
    const handleCloseShows = () => setShows(false); // Corrected the handler name here
    const handleShowShows = () => setShows(true);
    
return (
<>
<Button variant="primary" onClick={handleShow}>{BtnName}</Button>
<Modal show={show} onHide={handleClose} className="common_modal">
    <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
    </Modal.Header>
   <Modal.Body>
      <div className="row">
        <div className="col-md-12">
            <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid"/>
            <h3>The Bull Club</h3>
            <ul>
                <li><span>NFT Left</span><small>988/ 1,000</small></li>
                <li><span>Auction Price</span><small>0.055 ETH</small></li>
                <li><span>Enter your Offer Price</span><small>0.100 ETH</small></li>
            </ul>
            {/* <a href="#" ariant="primary" onClick={handdleshows}>Place Bid</a> */}
            <Button variant="primary" onClick={handleShowShows}>Place Bid</Button>
        </div>
      </div>
   </Modal.Body>
   
</Modal>







<Modal show={shows} onHide={handleCloseShows} className="common_modal place_bid">
    <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
    </Modal.Header>
   <Modal.Body>
      <div className="row">
        <div className="col-md-12 text-center">
            <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid"/>
            <h3>The Bird Art</h3>
            <p className="green">Congratulations you placed a bid successfully</p>
            <span><img src={require("../../assets/images/eth.png")} alt="eth" className="img-fluid"/>View on Etherscan</span>
          <p><a href="/collections" ariant="primary">Go to your Collection</a></p> 
        </div>
      </div>
   </Modal.Body>
   
</Modal>




</>
);


};
export const Mintcollectionsmodal = (props) => {
  // const handleCloseOpen = () => setShows(true);
  const handleShowModal = () =>{ setShows(true); 
    props.onHide()
  };
  const [shows, setShows] = useState(false);
  const [showNext, setShowNext] = useState(false);
  
    const handleCloseModal = () => setShows(false);
    const handleNextModal = () => {
      handleCloseModal();
      setShowNext(prev=>!prev)
    }; // Function to close the first modal
 console.log(props )
      
  return(
    <>
      <Modal {...props}  className="common_modal minting-modal">
          <Modal.Header closeButton>
                {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
        <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                  <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid"/>
                  <h3>The Bird Art</h3>
                  <ul>
                      <li><span>Your NFT</span><small>4/ 1,000</small></li>
                      <li><span>Per Mint Price</span><small>0.055 ETH</small></li>
                      <li><span>Your NFT Price</span><small>0.100 ETH</small></li>
                  </ul>
                  {/* <a href="#" ariant="primary" onClick={handdleshows}>Place Bid</a> */}
                  <Button variant="primary" onClick={handleShowModal}   >Mint Now</Button>
              </div>
            </div>
        </Modal.Body>
      </Modal>


 {/* Second Modal */}

        <Modal show={shows} onHide={handleCloseModal} className="common_modal place_bid">
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 text-center">
                <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid" />
                <h3>The Bird Art</h3>
                <p className="green">Congratulations you placed a bid successfully</p>
                <span>
                  <img src={require("../../assets/images/eth.png")} alt="eth" className="img-fluid" />
                  View on Etherscan
                </span>
                <p>
                <Button variant="primary" onClick={handleNextModal}   >Next</Button>
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
   


        <Modal show={showNext} onHide={handleNextModal} className="common_modal place_bid">
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 text-center">
                <ul>
                  <li><img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid" /></li>
                  <li><img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid" /></li>
                  <li><img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid" /></li>
                  <li><img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid" /></li>
                </ul>
                <h3>The Bird Art</h3>
                <p className="green">Congratulations you got these NFT’s</p>
                <p><a href="/collections" ariant="primary">Go to your Collection</a></p> 
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
)}
