import React, { useRef, useState } from 'react';
import {Form, Button, Dropdown } from "react-bootstrap";
import { FaSearch, FaBars, FaTimes } from "react-icons/fa";


const Collectionheader = () => {
return (
<>

<section className='fillter_nav'>
   <div className='container-fluid'>
      <div className='row'>
         <div className='col-md-3'>
            <div className='filtters'>
               <ul>
                  <li><a href='#'>Filter</a></li>
                  <li><a href='#'><span>.</span>Live</a></li>
                  <li><a href='#'>3,767 results</a></li>
               </ul>
            </div>
         </div>
         <div className='col-md-7'>
            <div className='search'>
               <ul>
                  <li>
                     <Form.Control type="text" name="search" placeholder="search by name or trait" id=""/>
                     <FaSearch />
                  </li>
                  <li>
                     <Form.Select aria-label="Default select example">
                        <option value="1">Price low to high</option>
                        <option value="2">Price medium to high</option>
                        <option value="3">Price high to high</option>
                     </Form.Select>
                  </li>
               </ul>
            </div>
         </div>
         <div className='col-md-2'>
            <div className='switch'>
               <ul>
                  <li>
                     <a href='#'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                           <rect x="0.5" y="0.5" width="13" height="13" rx="0.5" stroke="#D9D9D9"/>
                           <rect x="18.5" y="0.5" width="13" height="13" rx="0.5" stroke="#D9D9D9"/>
                           <rect x="0.5" y="18.5" width="13" height="13" rx="0.5" stroke="#D9D9D9"/>
                           <rect x="18.5" y="18.5" width="13" height="13" rx="0.5" stroke="#D9D9D9"/>
                        </svg>
                     </a>
                  </li>
                  <li>
                     <a href='#'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                           <rect x="0.5" y="0.5" width="13" height="6" rx="0.5" stroke="#D9D9D9"/>
                           <rect x="0.5" y="12.5" width="13" height="6" rx="0.5" stroke="#D9D9D9"/>
                           <rect x="0.5" y="25.5" width="13" height="6" rx="0.5" stroke="#D9D9D9"/>
                           <rect x="16.5" y="0.5" width="6" height="6" rx="0.5" stroke="#D9D9D9"/>
                           <rect x="16.5" y="12.5" width="6" height="6" rx="0.5" stroke="#D9D9D9"/>
                           <rect x="16.5" y="25.5" width="6" height="6" rx="0.5" stroke="#D9D9D9"/>
                           <rect x="25.5" y="0.5" width="6" height="6" rx="0.5" stroke="#D9D9D9"/>
                           <rect x="25.5" y="12.5" width="6" height="6" rx="0.5" stroke="#D9D9D9"/>
                           <rect x="25.5" y="25.5" width="6" height="6" rx="0.5" stroke="#D9D9D9"/>
                        </svg>
                     </a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</section>

</>
);
};
export default Collectionheader;