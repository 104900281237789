import React, { useEffect, useRef, useState } from "react";
import "./profile.css";
import { Form, Button, Dropdown } from "react-bootstrap";
import { FaSearch, FaRegEdit, FaTimes } from "react-icons/fa";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Collections from "./Collections/collections";
import Offermade from "./OfferMade/offermade";
import Created from "./Created/created";
import Favourite from "./Favourited/favourited";
import Activity from "./Activity/activity";
import Purchase from "./Purchase/purchase";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
// import { Formik, useFormik } from "formik";
import { API_BASE_URL } from "../../Utility/Urls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Link, Routes, useParams } from "react-router-dom";

const Profile = () => {
  const [selectedKey, setKey] = useState("Collections");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const { tab } = useParams();

  useEffect(() => {
   if(tab == "purchase"){
      setKey("Purchase");
   }
  }, [])

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    // Create a FileReader to read the image
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the selected image and its preview
      setSelectedImage({
        file: file,
        preview: reader.result,
      });
    };

    if (file) {
      // Read the image as a data URL
      reader.readAsDataURL(file);
    }
  };
  const handleSave = async () => {
    console.log("data>>>>>>>>>>>>>>>>", name, address, description);
    try {
      const signUpResponse = await axios.post(`${API_BASE_URL}/user/sign-up`, {
        description: description,
        walletaddress: address,
        username: name,
      });

      console.log("Sign Up API Response:", signUpResponse.data);

      const userid = signUpResponse.data.data._id;
      const token = signUpResponse.data.data.token;

      const headers = {
        Authorization: `${token}`,
        "Access-Control-Allow-Credentials": true,
      };

      console.log(headers);

      if (selectedImage) {
        const formData = new FormData();
        formData.append("file", selectedImage.file);
        formData.append("mediable_id", userid);
        formData.append("mediable_type", "profile");

        const profileImageResponse = await axios.post(
          `${API_BASE_URL}/user/uploadFile`,
          formData,
          {
            headers: {
              ...headers,
              "Content-Type": "multipart/form-data", // Important for file upload
            },
          }
        );

        console.log(
          "Profile Image Upload Response:",
          profileImageResponse.data
        );
      }

      // Handle the response as needed
      if (signUpResponse.status === 200) {
        console.log("Profile data saved successfully.");
        handleClose(); // Close the modal after successful API call
        localStorage.setItem(
          "profileData",
          JSON.stringify(signUpResponse.data)
        );

        // Show a toast notification
        toast.success("Profile data saved successfully!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000, // Duration of the toast in milliseconds
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        console.error("Sign Up API Error:", signUpResponse.statusText);
        // Handle error scenarios
      }
    } catch (error) {
      console.error("API Error:", error);
      // Handle error scenarios
    }
  };

  return (
    <>
      <section className="collection-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 banner-heading position-relative text-start">
              <a href="/explore">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                >
                  <path
                    d="M21 9C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7V9ZM0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM21 7L1 7V9L21 9V7Z"
                    fill="white"
                  />
                </svg>
                Profile
              </a>
              <div className="adit-profile">
                <div className="user-profile">
                  {selectedImage ? (
                    <img
                      src={selectedImage.preview}
                      alt="Selected"
                      className="img-fluid show_preview"
                    />
                  ) : (
                    <img
                      src={require("../../assets/images/user.png")}
                      alt="Profile"
                      className="defolt_icon"
                    />
                  )}
                  <Button variant="primary" onClick={handleShow}>
                    <FaRegEdit />
                  </Button>
                </div>
                <div className="profile-text">
                  <p>
                    <strong>name :- </strong>
                    {name}
                  </p>
                  <p>
                    <strong>Wallet Key :- </strong>
                    {localStorage.getItem("walletAddress")}
                  </p>
                  <p>
                    <strong>Description :- </strong>
                    {description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fillter_nav p-0 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 flex-box">
              <div className="filtters search">
                <ul>
                  <li>
                    <a href="#">Filter</a>
                  </li>
                  <li>
                    <Form.Select aria-label="Default select example">
                      <option value="1">Status</option>
                      <option value="2">Status</option>
                      <option value="3">Status</option>
                    </Form.Select>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7 w-flex">
              <div className="search">
                <ul>
                  <li>
                    <Form.Control
                      type="text"
                      name="search"
                      placeholder="search by name or trait"
                      id=""
                    />
                    <FaSearch />
                  </li>
                  <li>
                    <Form.Select aria-label="Default select example">
                      <option value="1">Price low to high</option>
                      <option value="2">Price medium to high</option>
                      <option value="3">Price high to high</option>
                    </Form.Select>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              <div className="switch">
                <ul>
                  <li>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="13"
                          height="13"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                        <rect
                          x="18.5"
                          y="0.5"
                          width="13"
                          height="13"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                        <rect
                          x="0.5"
                          y="18.5"
                          width="13"
                          height="13"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                        <rect
                          x="18.5"
                          y="18.5"
                          width="13"
                          height="13"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="13"
                          height="6"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                        <rect
                          x="0.5"
                          y="12.5"
                          width="13"
                          height="6"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                        <rect
                          x="0.5"
                          y="25.5"
                          width="13"
                          height="6"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                        <rect
                          x="16.5"
                          y="0.5"
                          width="6"
                          height="6"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                        <rect
                          x="16.5"
                          y="12.5"
                          width="6"
                          height="6"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                        <rect
                          x="16.5"
                          y="25.5"
                          width="6"
                          height="6"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                        <rect
                          x="25.5"
                          y="0.5"
                          width="6"
                          height="6"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                        <rect
                          x="25.5"
                          y="12.5"
                          width="6"
                          height="6"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                        <rect
                          x="25.5"
                          y="25.5"
                          width="6"
                          height="6"
                          rx="0.5"
                          stroke="#D9D9D9"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row bg-black">
            <div className="col-md-12 p-0">
              <Tabs
                activeKey={selectedKey}
                onSelect={(k) => setKey(k)}
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Collections" title="Collections">
                  <Collections />
                </Tab>
                <Tab eventKey="Offer Made" title="Offer Made">
                  <Offermade />
                </Tab>
                <Tab eventKey="Created" title="Created">
                  <Created />
                </Tab>
                <Tab eventKey="Favourited" title="Favourited">
                  <Favourite />
                </Tab>
                <Tab eventKey="Activity" title="Activity">
                  <Activity />
                </Tab>
                <Tab eventKey="Purchase" title="Purchase">
                  <Purchase />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      {/* adit profile modal popup */}
      <Modal
        show={show}
        onHide={handleClose}
        className="edit_profile common_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="choose_img">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              <FaRegEdit />
              {selectedImage && (
                <div className="preview_img">
                  <img
                    src={selectedImage.preview}
                    alt="Selected Preview"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                </div>
              )}
            </div>
            <Form.Group className="mb-3 name_controller" controlId="">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text" // Use "text" type for name input
                placeholder="Change Your Name"
                autoFocus
                onChange={(e) => setName(e.target.value)} // Update name state on input change
              />
            </Form.Group>
            <Form.Group className="mb-0" controlId="">
              <Form.Label>Wallet Key</Form.Label>
              <Form.Control
                id="default_key"
                placeholder="Address"
                type="text"
                value={localStorage.getItem("walletAddress")} // Set the input value to the wallet address from local storage
                onChange={(e) => setAddress(e.target.value)} // Update address state on input change if needed
              />
            </Form.Group>
            <Form.Group className="mb-0" controlId="">
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="Description"
                as="textarea"
                rows={3}
                onChange={(e) => setDescription(e.target.value)} // Update address state on input change
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};
export default Profile;
