
import "./explore.css";
import Slider from "react-slick";
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaHeart } from "react-icons/fa";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import slideImage from '../../assets/images/slide-1.png'
import userImage from '../../assets/images/userimg.png'


// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';



const Explore = () => {

  const sliderData = [
    {
      userImage: "../../assets/images/userimg.png",
      userName: "Fighter king",
      slideImage: "../../assets/images/slide-1.png",
      hearts: 50,
      title: "The Fighter 85",
      date: "2 weeks ago",
      price: "0.56 ETH",
    },
    {
      userImage: "../../assets/images/userimg.png",
      userName: "Fighter king",
      slideImage: "../../assets/images/slide-1.png",
      hearts: 50,
      title: "The Fighter 85",
      date: "2 weeks ago",
      price: "0.56 ETH",
    },
    {
      userImage: "../../assets/images/userimg.png",
      userName: "Fighter king",
      slideImage: "../../assets/images/slide-1.png",
      hearts: 50,
      title: "The Fighter 85",
      date: "2 weeks ago",
      price: "0.56 ETH",
    },
    {
      userImage: "../../assets/images/userimg.png",
      userName: "Fighter king",
      slideImage: "../../assets/images/slide-1.png",
      hearts: 50,
      title: "The Fighter 85",
      date: "2 weeks ago",
      price: "0.56 ETH",
    },
    {
      userImage: "../../assets/images/userimg.png",
      userName: "Fighter king",
      slideImage: "../../assets/images/slide-1.png",
      hearts: 50,
      title: "The Fighter 85",
      date: "2 weeks ago",
      price: "0.56 ETH",
    },
    // Add more objects for additional slides...
  ];

  const totalSlides = 10;
  const middleSlideIndex = Math.floor(totalSlides / 2);

const settings = {
  arrows:false,
  dots: false,
  infinite: true,
  speed: 500,
  autoplay:true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: true,
  centerPadding: '80px',
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,

      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      },
    },
  ],
};

/*---------------------slider-------------*/
const popular = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,

      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      },
    },
  ],
};
    return (
    <>
      <section className="itemSlider Categories-data">
        <div className="container">
          <div className="row">
            <div className="col-md-12 main-heading">
              <h3>Categories</h3>
            </div>
          </div>
          <Slider {...settings}>
            <div>
              <div className="card-slider">
                <div className="main_img">
                    <img src={require("../../assets/images/slide-1.png")} alt="slide-1" className="img-fluid"/>
                    <a href="/collections">Art</a>
                </div>
              </div>
            </div>
            <div>
            <div className="card-slider">
                <div className="main_img">
                    <img src={require("../../assets/images/slide-1.png")} alt="slide-1" className="img-fluid"/>
                    <a href="/collections">Gaming</a>
                </div>
              </div>
            </div>
            <div>
            <div className="card-slider">
                <div className="main_img">
                    <img src={require("../../assets/images/slide-1.png")} alt="slide-1" className="img-fluid"/>
                    <a href="/collections">Music</a>
                </div>
              </div>
            </div>
            <div>
            <div className="card-slider">
                <div className="main_img">
                    <img src={require("../../assets/images/slide-1.png")} alt="slide-1" className="img-fluid"/>
                    <a href="/collections">Photography</a>
                </div>
              </div>
            </div>
            <div>
            <div className="card-slider">
                <div className="main_img">
                    <img src={require("../../assets/images/slide-1.png")} alt="slide-1" className="img-fluid"/>
                    <a href="/collections">Music</a>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
{/* ---------------swipper slider---------------------- */}

      <section className="swipper_slider">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-6 main-heading">
              <h3><img src={require("../../assets/images/king.png")} alt="king" className="img-fluid"/>Premium Collections</h3>
            </div>
            <div className="col-md-6 text-end">
              <a href="/profile" className="View_all">View all</a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
                  <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              autoplay={{
                delay: 100,
                disableOnInteraction: false,
              }}
              zoom={true}
              spaceBetween={0}
              slidesPerView={3}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 0,
                modifier: 2,
                zoom:true,
                slideShadows: false,
              }}
              pagination={true}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper"
              initialSlide={middleSlideIndex}
            >
              <SwiperSlide>
                <img src={require("../../assets/images/active-slider.png")} alt="king" className="img-fluid"/>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                   
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../assets/images/active-slider.png")} alt="king" className="img-fluid"/>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../assets/images/active-slider.png")} alt="king" className="img-fluid"/>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../assets/images/active-slider.png")} alt="king" className="img-fluid"/>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../assets/images/active-slider.png")} alt="king" className="img-fluid"/>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                   
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../assets/images/active-slider.png")} alt="king" className="img-fluid"/>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../assets/images/active-slider.png")} alt="king" className="img-fluid"/>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                   
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../assets/images/active-slider.png")} alt="king" className="img-fluid"/>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                   
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../assets/images/active-slider.png")} alt="king" className="img-fluid"/>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    
                  </ul>
                </div>
              </SwiperSlide>
            </Swiper>
            </div>
          </div>
        </div>
    </section>
{/* ------------slider--------------- */}
      <section className="itemSlider pt-5 mt-5">
      <div className="container">
        <div className="row">
        <div className="col-md-6 main-heading">
              <h3>Most Popular Art NFTs</h3>
            </div>
            <div className="col-md-6 text-end">
              <a href="/profile" className="View_all">View all</a>
            </div>
        </div>
        <Slider {...popular}>
        {sliderData.map((slide, index) => (
              <div key={index}>
                <div className="card-slider">
                  <span>
                    <img src={userImage} alt="user" className="img-fluid" />
                    {slide.userName}
                  </span>
                  <div className="main_img">
                    <img src={slideImage} alt={`slide-${index + 1}`} className="img-fluid" />
                    <a href="wishlist">
                      <FaHeart />
                      {slide.hearts}
                    </a>
                  </div>
                  <div className="row slider_data">
                    <div className="col-md-6">
                      <h6>{slide.title}</h6>
                      <small>{slide.date}</small>
                      <a href="/profile" className="view_all">
                        View All
                      </a>
                    </div>
                    <div className="col-md-6">
                      <p>Price: <strong>{slide.price}</strong></p>
                      {/* Add more data for the slide as needed */}
                      <a href="/mintcollectionsdetails" className="Buy Now">
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
      </section>
  {/*----------- action------- */}
  <section className="actions">
        <div className="container">
          <div className="row">
            <div className="col-md-6 main-heading">
              <h3>Live Actions</h3>
            </div>
            <div className="col-md-6 text-end">
              <a href="/collections" className="View_all">View all</a>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="action_card">
                <div className="single-card-img">
                  <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid card-effact"/>
                  <img src={require("../../assets/images/action2.png")} alt="action1" className="img-fluid card-effact2"/>
                  <div className="img-caption">
                    <p>Auction</p>
                    <h3>05:44:36</h3>
                  </div>
                </div>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <g clip-path="url(#clip0_481_787)">
                          <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_481_787">
                          <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                          </clipPath>
                          </defs>
                          </svg>50
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="action_card">
                <div className="single-card-img">
                <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid card-effact"/>
                  <img src={require("../../assets/images/action2.png")} alt="action1" className="img-fluid card-effact2"/>
                  <div className="img-caption">
                    <p>Auction</p>
                    <h3>05:44:36</h3>
                  </div>
                </div>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <g clip-path="url(#clip0_481_787)">
                          <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_481_787">
                          <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                          </clipPath>
                          </defs>
                          </svg>50
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="action_card">
                <div className="single-card-img">
                  <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid card-effact"/>
                  <img src={require("../../assets/images/action2.png")} alt="action1" className="img-fluid card-effact2"/>
                  <div className="img-caption">
                    <p>Auction</p>
                    <h3>05:44:36</h3>
                  </div>
                </div>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <g clip-path="url(#clip0_481_787)">
                          <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_481_787">
                          <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                          </clipPath>
                          </defs>
                          </svg>50
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="action_card">
                <div className="single-card-img">
                  <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid card-effact"/>
                  <img src={require("../../assets/images/action2.png")} alt="action1" className="img-fluid card-effact2"/>
                  <div className="img-caption">
                    <p>Auction</p>
                    <h3>05:44:36</h3>
                  </div>
                </div>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <g clip-path="url(#clip0_481_787)">
                          <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_481_787">
                          <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                          </clipPath>
                          </defs>
                          </svg>50
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="action_card">
                <div className="single-card-img">
                  <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid card-effact"/>
                  <img src={require("../../assets/images/action2.png")} alt="action1" className="img-fluid card-effact2"/>
                  <div className="img-caption">
                    <p>Auction</p>
                    <h3>05:44:36</h3>
                  </div>
                </div>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <g clip-path="url(#clip0_481_787)">
                          <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_481_787">
                          <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                          </clipPath>
                          </defs>
                          </svg>50
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="action_card">
                <div className="single-card-img">
                <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid card-effact"/>
                  <img src={require("../../assets/images/action2.png")} alt="action1" className="img-fluid card-effact2"/>
                  <div className="img-caption">
                    <p>Auction</p>
                    <h3>05:44:36</h3>
                  </div>
                </div>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <g clip-path="url(#clip0_481_787)">
                          <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_481_787">
                          <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                          </clipPath>
                          </defs>
                          </svg>50
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="action_card">
                <div className="single-card-img">
                <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid card-effact"/>
                  <img src={require("../../assets/images/action2.png")} alt="action1" className="img-fluid card-effact2"/>
                  <div className="img-caption">
                    <p>Auction</p>
                    <h3>05:44:36</h3>
                  </div>
                </div>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <g clip-path="url(#clip0_481_787)">
                          <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_481_787">
                          <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                          </clipPath>
                          </defs>
                          </svg>50
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="action_card">
                <div className="single-card-img">
                  <img src={require("../../assets/images/action1.png")} alt="action1" className="img-fluid card-effact"/>
                  <img src={require("../../assets/images/action2.png")} alt="action1" className="img-fluid card-effact2"/>
                  <div className="img-caption">
                    <p>Auction</p>
                    <h3>05:44:36</h3>
                  </div>
                </div>
                <div className="card-caption">
                  <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                  <h4>Abstract girl</h4>
                  <p>0.08 ETH<strong>1/20</strong> </p>
                  <ul>
                    <li><a href="/collectionsdetails">Place Bid</a></li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <g clip-path="url(#clip0_481_787)">
                          <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_481_787">
                          <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                          </clipPath>
                          </defs>
                          </svg>50
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

    };
export default Explore;
