import React, { useState, useEffect } from "react";
import { Navbar, Nav, Form, Button, Dropdown } from "react-bootstrap";
import { FaSearch, FaBars, FaTimes } from "react-icons/fa";
import Logo from "../../assets/images/Logo.png";
import "./header.css";
import Wallet from "../wallet/wallet";
import {ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);
  const [connected, setConnected] = useState(
    !!localStorage.getItem("walletAddress")
  );
  const [menuOpen, setMenuOpen] = useState(false);

  console.log("Data stprage get", localStorage.getItem("walletAddress"));
  useEffect(() => {
    const handleScroll = () => {
      // Add or remove 'scrolling' class based on scroll position
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    // Add event listener to track scroll
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const handleToggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };
  function handleLogout() {
    // Remove wallet address from local storage
    localStorage.removeItem("walletAddress");

    // Display toast notification for logout
    toast.success("Logged out successfully!", {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }
  return (
    <header className={scrolling ? "scrolling" : ""}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Navbar expand="lg" bg="" variant="">
              <Navbar.Brand href="/">
                <img src={Logo} alt="Logo" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="navbarSupportedContent"
                onClick={handleToggleMenu}
              >
                {menuOpen ? <FaTimes /> : <FaBars />}
              </Navbar.Toggle>
              <Navbar.Collapse
                id="navbarSupportedContent"
                className={menuOpen ? "show" : ""}
              >
                <div className="search-bar">
                  <Form.Control
                    type="text"
                    name="search"
                    placeholder="Search items, collections and accounts"
                    id="search"
                  />
                  <FaSearch />
                </div>
                <Nav className="ms-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/explore">Explore</Nav.Link>
                  {/* <Nav.Link href="#">Author</Nav.Link> */}
                  <Nav.Link href="/stats">Stats</Nav.Link>
                </Nav>
                <Form className="d-flex connect_wallet">
                  <Wallet setConnected={setConnected}/>
                  {/* <Button variant="primary" className="wallet-btn">
                    Connect Wallet
                  </Button> */}
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img src={require("../../assets/images/user-1.png")} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="/profile">
                        <img
                          src={require("../../assets/images/user-profile.png")}
                          alt="Profile"
                        />
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item href="/collections">
                        <img
                          src={require("../../assets/images/coll.png")}
                          alt="My Collections"
                        />
                        My Collections
                      </Dropdown.Item>
                      <Dropdown.Item href="/profile">
                        <img
                          src={require("../../assets/images/nft.png")}
                          alt="My NFT"
                        />
                        My NFT
                      </Dropdown.Item>
                      <Dropdown.Item href="/createnft">
                        <img
                          src={require("../../assets/images/writing.png")}
                          alt="Create"
                        />
                        Create
                      </Dropdown.Item>
                      {connected ? (
                        <Dropdown.Item onClick={handleLogout}>
                          <img
                            src={require("../../assets/images/log-out.png")}
                            alt="Log out"
                          />
                          Log out
                        </Dropdown.Item>
                      ) : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form>
              </Navbar.Collapse>
            </Navbar>
            <ToastContainer />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
