// authMiddleware.js

const isAuthenticated = '';

export const requireAuth = (nextState, replace) => {
    console.log("vvvvv ",isAuthenticated);
  if (!isAuthenticated()) {
    // Redirect to the login page if the user is not authenticated
    replace('/profile');
  }
};
