import React, { useState, useEffect } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import "./mintingdetails.css";
import {Collectionsmodal} from "../../components/modals/collectionmodal";
import Table from 'react-bootstrap/Table';
import Slider from "react-slick";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaPlus,FaMinus } from "react-icons/fa";
import {Mintcollectionsmodal} from "../../components/modals/collectionmodal";

const MintingDetails = () => {
    const [quantity, setQuantity] = useState(0);

    const handleAddQuantity = () => {
      setQuantity(quantity + 1);
    };
  
    const handleRemoveQuantity = () => {
      if (quantity > 0) {
        setQuantity(quantity - 1);
      }
    };


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalShow, setModalShow] = useState(false);



    const settings = {
        arrows:false,
        dots: false,
        infinite: true,
        speed: 500,
        autoplay:true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        centerMode: true,
        centerPadding: '80px',
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
      
            },
          },
          {
            breakpoint: 767,
            settings: {
              dots: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true,
            },
          },
        ],
      };
      
  return (
    <>
        <section className="collection_details">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 banner-heading text-start">
                    <a href='/explore'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                            <path d="M21 9C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7V9ZM0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM21 7L1 7V9L21 9V7Z" fill="white"/>
                        </svg>
                        Collections
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <div className="collection-img">
                            <img src={require("../../assets/images/collection-img.png")} alt="action1" className="img-fluid"/>
                        </div>
                    </div>
                        <div className="col-md-7">
                            <div className="text-block">
                                <h3>The Bird Art</h3>
                                <h6>NFT NAME</h6>
                                <ul>
                                    <li><span>Created by  <strong>Abstract girl</strong></span></li>
                                    <li><span>Created on  <strong>May 2023</strong></span></li>
                                    <li><span>Price  <small>0.055 ETH</small></span></li>
                                </ul>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                <div className="progressbar-data">
                                    <ul>
                                        <li><p>5% minted</p></li>
                                        <li><span>0 / 1,000</span></li>
                                    </ul>
                                    <ProgressBar now={5} />
                                </div>
                                <div className="detail_modal">
                                    <ul>
                                        <li><h4>Mint To get NFT</h4></li>
                                        <li><span>May 10 at 10:30 PM GMT+5:30</span></li>
                                    </ul>
                                    <p>0.055 ETH</p>
                                    <ul className="pop-btn">
                                        <li>
                                            <div className="mint-flex">
                                                <button onClick={handleRemoveQuantity}><FaMinus/></button>
                                                <p>{quantity}</p>
                                                <button onClick={handleAddQuantity}><FaPlus/></button>
                                            </div>
                                        </li>
                                        <li><Button variant="primary" onClick={()=> setModalShow(true)}>Mint Now</Button></li>

                                    </ul>
                                      <Mintcollectionsmodal show={modalShow} onHide={()=> setModalShow(false)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

        <section className="table_block">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="table_box">
                            <h3>Description</h3>
                            <hr></hr>
                            <h4><span>By </span>SWALLOW-digital</h4>
                            <p>As the SWALLOW ink cell hums and glows, it breathes life into the machine, slowly unveiling the boundless potential within. What mysteries will be revealed? Only the holder can unleash its power!</p>
                            <hr></hr>
                            <h5>About SWALLOW Ink Cell</h5>
                            <hr></hr>
                            <h5>Details</h5>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="table_box p-0">
                            <h3 className="table-heading">Offers</h3>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Price</th>
                                        <th>USD Price</th>
                                        <th>Quantity</th>
                                        <th>Floor Differ</th>
                                        <th>Expiratior</th>
                                        <th>From</th>
                                    </tr>
                                </thead>
                                    <tbody>
                                        <tr>
                                            <td>0.0072 WETH</td>
                                            <td>$250</td>
                                            <td>26645644</td>
                                            <td>10 % below</td>
                                            <td>I Day ago</td>
                                            <td>fdgdfbgdf</td>
                                        </tr>
                                        <tr>
                                            <td>0.0072 WETH</td>
                                            <td>$250</td>
                                            <td>26645644</td>
                                            <td>10 % below</td>
                                            <td>I Day ago</td>
                                            <td>fdgdfbgdf</td>
                                        </tr>
                                        <tr>
                                            <td>0.0072 WETH</td>
                                            <td>$250</td>
                                            <td>26645644</td>
                                            <td>10 % below</td>
                                            <td>I Day ago</td>
                                            <td>fdgdfbgdf</td>
                                        </tr>
                                        <tr>
                                            <td>0.0072 WETH</td>
                                            <td>$250</td>
                                            <td>26645644</td>
                                            <td>10 % below</td>
                                            <td>I Day ago</td>
                                            <td>fdgdfbgdf</td>
                                        </tr> 
                                    </tbody>
                                </Table>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="table_block mt-5">
            <div className="container">
                <div className="row">
                <div className="col-md-9">
                        <div className="table_box p-0">
                            <h3 className="table-heading">Item Activity</h3>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th>Price</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                    <tbody>
                                        <tr>
                                            <td>Transfer</td>
                                            <td>0.0072 WETH</td>
                                            <td>26645644</td>
                                            <td>fdgdfbgdf</td>
                                            <td>in 2 hours</td>
                                        </tr>
                                        <tr>
                                            <td>Transfer</td>
                                            <td>0.0072 WETH</td>
                                            <td>26645644</td>
                                            <td>fdgdfbgdf</td>
                                            <td>in 2 hours</td>
                                        </tr>
                                        <tr>
                                            <td>Transfer</td>
                                            <td>0.0072 WETH</td>
                                            <td>26645644</td>
                                            <td>fdgdfbgdf</td>
                                            <td>in 2 hours</td>
                                        </tr>
                                        <tr>
                                            <td>Transfer</td>
                                            <td>0.0072 WETH</td>
                                            <td>26645644</td>
                                            <td>fdgdfbgdf</td>
                                            <td>in 2 hours</td>
                                        </tr>
                                    </tbody>
                                </Table>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="table_box">
                            <h3>Listing</h3>
                            <div className="nolist">
                                <p>No listing Yet</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>

        <section className="collection_slider">
            <div className="container">
            <div className="row">
                <div className="col-md-12 main-heading">
                <h3>Categories</h3>
                </div>
            </div>
            <Slider {...settings}>
                <div>
                <div className="card-slider position-relative">
                    <div className="main_img">
                        <img src={require("../../assets/images/collection1.png")} alt="slide-1" className="img-fluid"/>
                        <div className="img-caption"><p>Auction</p><h3>05:44:36</h3></div>
                    </div>
                    <div className="card-caption">
                        <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                        <h4>Abstract girl</h4>
                        <p>0.08 ETH<strong>1/20</strong> </p>
                        <ul>
                          <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                                <g clip-path="url(#clip0_481_787)">
                                <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_481_787">
                                <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                                </clipPath>
                                </defs>
                                </svg>50
                          </li>
                        </ul>
                      </div>
                </div>
                
                </div>
               
                <div>
                <div className="card-slider position-relative">
                    <div className="main_img">
                        <img src={require("../../assets/images/collection3.png")} alt="slide-1" className="img-fluid"/>
                        <div className="img-caption"><p>Auction</p><h3>05:44:36</h3></div>
                    </div>
                    <div className="card-caption">
                        <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                        <h4>Abstract girl</h4>
                        <p>0.08 ETH<strong>1/20</strong> </p>
                        <ul>
                          <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                                <g clip-path="url(#clip0_481_787)">
                                <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_481_787">
                                <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                                </clipPath>
                                </defs>
                                </svg>50
                          </li>
                        </ul>
                      </div>
                </div>
                </div>
                <div>
                <div className="card-slider position-relative">
                    <div className="main_img">
                        <img src={require("../../assets/images/collection4.png")} alt="slide-1" className="img-fluid"/>
                        <div className="img-caption"><p>Auction</p><h3>05:44:36</h3></div>
                    </div>
                    <div className="card-caption">
                        <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                        <h4>Abstract girl</h4>
                        <p>0.08 ETH<strong>1/20</strong> </p>
                        <ul>
                          <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                                <g clip-path="url(#clip0_481_787)">
                                <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_481_787">
                                <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                                </clipPath>
                                </defs>
                                </svg>50
                          </li>
                        </ul>
                      </div>
                </div>
                </div>
                <div>
                    <div className="card-slider position-relative">
                        <div className="main_img">
                            <img src={require("../../assets/images/collection1.png")} alt="slide-1" className="img-fluid"/>
                            <div className="img-caption"><p>Auction</p><h3>05:44:36</h3></div>
                        </div>
                        <div className="card-caption">
                            <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                            <h4>Abstract girl</h4>
                            <p>0.08 ETH<strong>1/20</strong> </p>
                            <ul>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                                    <g clip-path="url(#clip0_481_787)">
                                    <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_481_787">
                                    <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                                    </clipPath>
                                    </defs>
                                    </svg>50
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="card-slider position-relative">
                        <div className="main_img">
                            <img src={require("../../assets/images/collection1.png")} alt="slide-1" className="img-fluid"/>
                            <div className="img-caption"><p>Auction</p><h3>05:44:36</h3></div>
                        </div>
                        <div className="card-caption">
                            <img src={require("../../assets/images/artist.png")} alt="action1" className="img-fluid"/>
                            <h4>Abstract girl</h4>
                            <p>0.08 ETH<strong>1/20</strong> </p>
                            <ul>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                                    <g clip-path="url(#clip0_481_787)">
                                    <path d="M7.67383 12.8594C7.58008 12.9531 7.46549 13 7.33008 13C7.19466 13 7.08008 12.9531 6.98633 12.8594L2.11133 8.15625C2.05924 8.11458 1.98763 8.04688 1.89648 7.95312C1.80534 7.85938 1.66081 7.6888 1.46289 7.44141C1.26497 7.19401 1.08789 6.9401 0.931641 6.67969C0.775391 6.41927 0.636068 6.10417 0.513672 5.73438C0.391276 5.36458 0.330078 5.00521 0.330078 4.65625C0.330078 3.51042 0.660807 2.61458 1.32227 1.96875C1.98372 1.32292 2.89779 1 4.06445 1C4.38737 1 4.7168 1.05599 5.05273 1.16797C5.38867 1.27995 5.70117 1.43099 5.99023 1.62109C6.2793 1.8112 6.52799 1.98958 6.73633 2.15625C6.94466 2.32292 7.14258 2.5 7.33008 2.6875C7.51758 2.5 7.71549 2.32292 7.92383 2.15625C8.13216 1.98958 8.38086 1.8112 8.66992 1.62109C8.95898 1.43099 9.27148 1.27995 9.60742 1.16797C9.94336 1.05599 10.2728 1 10.5957 1C11.7624 1 12.6764 1.32292 13.3379 1.96875C13.9993 2.61458 14.3301 3.51042 14.3301 4.65625C14.3301 5.80729 13.7337 6.97917 12.541 8.17188L7.67383 12.8594Z" fill="#8D1832"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_481_787">
                                    <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0.330078 14)"/>
                                    </clipPath>
                                    </defs>
                                    </svg>50
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Slider>
            </div>
        </section>


        {/* create offer */}
        <Modal show={show} onHide={handleClose} className="common_modal make_offer">
            
        <Modal.Body>
            <div className="row">
                <div className="col-md-3">
                    <img src={require("../../assets/images/dronies-nft-dronie.png")} alt="action1" className="img-fluid"/>
                </div>
                <div className="col-md-9">
                        <Modal.Header closeButton>
                            <Modal.Title>Make an offer</Modal.Title>
                        </Modal.Header>
                        <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="text" placeholder="WETH" />
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Label>Offer Expioration</Form.Label>
                                <Form.Control type="text" placeholder="Offer Expioration" />
                            </Col>
                            <Col>
                                <Form.Label>Duration</Form.Label>
                                <Form.Control type="text" placeholder="Duration" />
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col className="d-flex">
                                <Form.Check aria-label="option 1" />
                                <span>By checking this box, I agree to Open sea’s Terms of Service</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" className="makeoffer">Make an Offer</Button>
                            </Col>
                        </Row>
                  </Form>
                </div>
            </div>
        </Modal.Body>
    </Modal>
    </>
  );
};

export default MintingDetails;
