import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom'; // Add this import
import Sidebar from '../../sidebar/sidebar';
import Product from '../../product/product';

const Purchase = () => {
  return (
    <>
      <section className='collection-wrapper'>
        <div className='container-fluid p-0'>
          <div className='row'>
            <div className='col-md-3 p-0'>
              <Sidebar/>
            </div>
            <div className='col-md-9'>
              <Product/>
            </div>
          </div>
          {/* <Link to="/profile/Purchase" className="view_item">View Item</Link> */}
        </div>
      </section>
    </>
  );
};
export default Purchase;
