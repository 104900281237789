import React, { useState } from "react";
import Slider from "react-slick";
import { FaHeart } from "react-icons/fa";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,

      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      },
    },
  ],
};

const Categories = () => {
  return (
    <>
    <section className="itemSlider home_categories">
      <div className="container">
        <div className="row">
          <div className="col-md-12 main-heading">
            <h3>Categories</h3>
          </div>
        </div>
        <Slider {...settings}>
          <div>
            <div className="card-slider">
              <div className="main_img">
                  <img src={require("../../assets/images/slide-1.png")} alt="slide-1" className="img-fluid"/>
                  <a href="/collections"><h3>Art</h3></a>
              </div>
            </div>
          </div>
          <div>
          <div className="card-slider">
              <div className="main_img">
                  <img src={require("../../assets/images/slide-1.png")} alt="slide-1" className="img-fluid"/>
                  <a href="/collections"><h3>Gaming</h3></a>
              </div>
            </div>
          </div>
          <div>
          <div className="card-slider">
              <div className="main_img">
                  <img src={require("../../assets/images/slide-1.png")} alt="slide-1" className="img-fluid"/>
                  <a href="/collections"><h3>Music</h3></a>
              </div>
            </div>
          </div>
          <div>
          <div className="card-slider">
              <div className="main_img">
                  <img src={require("../../assets/images/slide-1.png")} alt="slide-1" className="img-fluid"/>
                  <a href="/collections"><h3>Photography</h3></a>
              </div>
            </div>
          </div>
          <div>
          <div className="card-slider">
              <div className="main_img">
                  <img src={require("../../assets/images/slide-1.png")} alt="slide-1" className="img-fluid"/>
                  <a href="/collections"><h3>Art</h3></a>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      </section>
    </>
  );
};
export default Categories;
