import React, { useRef, useState } from 'react';
import "./collections.css";
import Collectionbanner from '../../components/collectionBanner/collectionbanner';
import Collectionheader from '../../components/collectionHeader/collectionheader';
import Sidebar from '../../components/sidebar/sidebar';
import Product from '../../components/product/product';
import Allcollections from '../../components/allcollection/allcollection';


const Collections = () => {
return (
<>
<Collectionbanner/>
<Collectionheader/>
{/* <Allcollections/> */}
{/* ---------fillter sidebar------------ */}
<section className='collection-wrapper'>
   <div className='container-fluid p-0'>
      <div className='row'>
         <div className='col-md-3 p-0'>
           <Sidebar/>
         </div>
         <div className='col-md-9'>
            <Product/>
         </div>
      </div>
   </div>
</section>
</>
);
};
export default Collections;