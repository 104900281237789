import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaWallet } from "react-icons/fa";
import axios from "axios";
import { API_BASE_URL } from "../../Utility/Urls";

function Wallet({setConnected}) {
  const [web3Provider, setWeb3Provider] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [balance, setBalance] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);

  const forcenetwork = async () => {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }], // chainId must be in hexadecimal numbers
    });
  };
  useEffect(() => {
    // Fetch address and balance when web3Provider is available
    if (web3Provider) {
      fetchUserAddress();
      fetchBalance();
    }
  }, [web3Provider]);

  useEffect(() => {
    // Save the wallet address in local storage whenever it changes
    if (userAddress) {
      localStorage.setItem("walletAddress", userAddress);
    }
  }, [userAddress]);

  useEffect(() => {
    // Save the wallet address in local storage whenever it changes

    const walletAddress = localStorage.getItem("walletAddress");

    setWalletAddress(walletAddress);
  }, [userAddress]);

  async function connectWallet() {
    try {
      if (window.ethereum) {
        await forcenetwork();

        await window.ethereum.request({ method: "eth_requestAccounts" });
        const web3ModalProvider = new ethers.providers.Web3Provider(
          window.ethereum
        );
        setWeb3Provider(web3ModalProvider);
        setConnected(true);
        console.log("1");
        console.log("2");
        window.location.reload();
      } else {
        setTimeout(() => {
          window.open(
            "https://metamask.app.link/dapp/nft.crypton.media/buy-now/"
          );
        }, 1000);
      }  
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchUserAddress() {
    try {
      const signer = web3Provider.getSigner();
      const address = await signer.getAddress();
      setUserAddress(address);
      // setAddress(address);
     
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchBalance() {
    try {
      const signer = web3Provider.getSigner();
      const address = await signer.getAddress();
      const balance = await web3Provider.getBalance(address);
      setBalance(ethers.utils.formatEther(balance));
    } catch (error) {
      console.error(error);
    }
  }

 
  const handleLogin = async (event) => {
    console.log(walletAddress, "walletAddress in login>>>>>>>>>");
    event?.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/User/login`, {
        walletaddress: walletAddress,
      });

      if (response.status === 200) {
        // Assuming you want to store the response data in local storage
        localStorage.setItem("user-info", JSON.stringify(response.data?.data.token));
        console.log("Asset created successfully:", response.data?.data.token);

        // Do any additional handling of the successful response here
      } else if (response.status === 201) {

          console.log('I am here', response)

          const responsesignup = await axios.post(`${API_BASE_URL}/User/sign-up`, {
            walletaddress: walletAddress,
            username: 'unnamed',
            description : 'test descripiton'
          });
          if (responsesignup.status === 200) {
          // Assuming you want to store the response data in local storage
          localStorage.setItem("user-info", JSON.stringify(responsesignup.data?.data.token));
          console.log("Asset created successfully:", responsesignup.data?.data.token);

          // Do any additional handling of the successful response here
          } else {
          console.error("API Error:", responsesignup.status, responsesignup.statusText);
          // Handle other error scenarios
          }

      } else {
      
        console.error("API Error:", response.status, response.statusText);
     
      }
    } catch (error) {
      console.error("API Error:", error);
      // Handle error scenarios
    }
  };


  useEffect(() => {
    // Save the wallet address in local storage whenever it changes

    handleLogin();

  }, [walletAddress]);





  
  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Wallet Address</strong>
      <br />
      <p>{userAddress}</p>
      {/* <p>{balance}ETH</p> */}
    </Tooltip>
  );
  console.log("connect wllet", walletAddress);

  return (
    <>
      {walletAddress === null ? (
        <>
          <Button onClick={connectWallet} className="wallet-btn">
            Connect Wallet
          </Button>
        </>
      ) : (
        <div>
          <OverlayTrigger placement="bottom" overlay={tooltip}>
            <Button className="wallet-btn">
              <FaWallet /> Connected
            </Button>
            {/* {balance !== null && <span> | Balance: {balance} ETH</span>} */}
          </OverlayTrigger>
        </div>
      )}
      {/* <div>
          <Button onClick={handleLogout}>Logout</Button>
        </div> */}
      {/* <Button onClick={handleLogin} className="wallet-btn">
        login
      </Button> */}
    </>
  );
}

export default Wallet;
