import React, { useRef, useState } from 'react';
import {Form, Button, Dropdown } from "react-bootstrap";
import { FaSearch, FaBars, FaTimes } from "react-icons/fa";
import Accordion from 'react-bootstrap/Accordion';


const Sidebar = () => {
return (
<>
<div className='sidebar'>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Status</Accordion.Header>
                        <Accordion.Body>
                        <Form.Check
                            inline
                            label="Listed"
                            name="group1"
                        />
                        <Form.Check
                            inline
                            label="Listed"
                            name="group1"
                        />
                        <Form.Check
                            inline
                            label="On auction"
                            name="group1"
                        />
                        <Form.Check
                            inline
                            label="New"
                            name="group1"
                        />
                        <Form.Check
                            inline
                            label="Has offers"
                            name="group1"
                        />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Price</Accordion.Header>
                        <Accordion.Body>
                            <ul className='price'>
                                <li><Form.Select aria-label="Default select example">
                                <option>USD</option>
                                <option value="2">ETH</option>
                                <option value="3">USD</option>
                                <option value="4">SOL</option>
                                </Form.Select></li>
                                <li>
                                    <Form.Group className="mb-3">
                                        <Form.Control id="disabledTextInput" placeholder="Min" />
                                    </Form.Group>
                                </li>
                                <li><span>to</span></li>
                                <li>
                                    <Form.Group className="mb-3">
                                        <Form.Control id="disabledTextInput" placeholder="Max" />
                                    </Form.Group>
                                </li>
                            </ul>
                            <Button className='apply'>Apply</Button>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Quantity</Accordion.Header>
                        <Accordion.Body>
                            <Form.Check type="radio" aria-label="radio 1" label="All Items" />
                            <Form.Check type="radio" aria-label="radio 1" label="Single Items" />
                            <Form.Check type="radio" aria-label="radio 1" label="Bundles" />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Top Artist</Accordion.Header>
                        <Accordion.Body>
                            <div className='search'>
                                <Form.Control type="text" name="search" placeholder="search by name or trait" id=""/>
                                <FaSearch />
                            </div>
                            <ul className='user-artist'>
                                <li><img src={require("../../assets/images/artist-user.png")} alt="action1" className="img-fluid"/>Leslie Alexander</li>
                                <li><img src={require("../../assets/images/artist-user.png")} alt="action1" className="img-fluid"/>Leslie Alexander</li>
                                <li><img src={require("../../assets/images/artist-user.png")} alt="action1" className="img-fluid"/>Leslie Alexander</li>
                                <li><img src={require("../../assets/images/artist-user.png")} alt="action1" className="img-fluid"/>Leslie Alexander</li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
</>
);
};
export default Sidebar;