import React, { useState } from "react";
import Slider from "react-slick";
import { FaHeart } from "react-icons/fa";
import slideImage from '../../assets/images/slide-1.png'
import userImage from '../../assets/images/userimg.png'
import {BuyNowmodal} from "../../components/modals/buynow_modal";



const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,

      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      },
    },
  ],
};

const ProductItems = () => {

  
  const sliderData = [
    {
      userImage: "../../assets/images/userimg.png",
      userName: "Fighter king",
      slideImage: "../../assets/images/slide-1.png",
      hearts: 50,
      title: "The Fighter 85",
      date: "2 weeks ago",
      price: "0.56 ETH",
    },
    // {
    //   userImage: "../../assets/images/userimg.png",
    //   userName: "Fighter king",
    //   slideImage: "../../assets/images/slide-1.png",
    //   hearts: 50,
    //   title: "The Fighter 85",
    //   date: "2 weeks ago",
    //   price: "0.56 ETH",
    // },
    // {
    //   userImage: "../../assets/images/userimg.png",
    //   userName: "Fighter king",
    //   slideImage: "../../assets/images/slide-1.png",
    //   hearts: 50,
    //   title: "The Fighter 85",
    //   date: "2 weeks ago",
    //   price: "0.56 ETH",
    // },
    // {
    //   userImage: "../../assets/images/userimg.png",
    //   userName: "Fighter king",
    //   slideImage: "../../assets/images/slide-1.png",
    //   hearts: 50,
    //   title: "The Fighter 85",
    //   date: "2 weeks ago",
    //   price: "0.56 ETH",
    // },
    // {
    //   userImage: "../../assets/images/userimg.png",
    //   userName: "Fighter king",
    //   slideImage: "../../assets/images/slide-1.png",
    //   hearts: 50,
    //   title: "The Fighter 85",
    //   date: "2 weeks ago",
    //   price: "0.56 ETH",
    // },
    // Add more objects for additional slides...
  ];


  
  return (
    <>
    <section className="itemSlider pt-0">
      <div className="container">
        <div className="row">
          <div className="col-md-12 main-heading">
            <h3>New Items</h3>
          </div>
        </div>
        <Slider {...settings}>
        {sliderData.map((slide, index) => (
              <div key={index}>
                <div className="card-slider">
                  <span>
                    <img src={userImage} alt="user" className="img-fluid" />
                    {slide.userName}
                  </span>
                  <div className="main_img">
                    <img src={slideImage} alt={`slide-${index + 1}`} className="img-fluid" />
                    <a href="wishlist">
                      <FaHeart />
                      {slide.hearts}
                    </a>
                  </div>
                  <div className="row slider_data">
                    <div className="col-md-6">
                      <h6>{slide.title}</h6>
                      <small>{slide.date}</small>
                      <a href="/profile" className="view_all">
                        View All
                      </a>
                    </div>
                    <div className="col-md-6">
                      <p>Price: <strong>{slide.price}</strong></p>
                      {/* Add more data for the slide as needed */}
                      {/* <a href="/mintcollectionsdetails" className="Buy Now">
                        Buy Now
                      </a> */}
                      <BuyNowmodal BuyNow={"Buy Now"}/>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
      </section>
    </>
  );
};
export default ProductItems;
