import React, { useRef, useState } from 'react';
import Sidebar from '../../sidebar/sidebar';
import Product from '../../product/product';

const Created = () => {
return (
<>
<section className='collection-wrapper'>
    <div className='container-fluid p-0'>
        <div className='row'>
            <div className='col-md-3 p-0'>
                <Sidebar/>
            </div>
            <div className='col-md-9'>
                <Product/>
            </div>
        </div>
    </div>
</section>             
</>
);
};
export default Created;