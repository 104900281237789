import React from "react";
import "./footer.css";
import Logo from "../../assets/images/Logo.png";
import { Navbar, Nav, Form, Button, Dropdown } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row footerTopBar">
                    <div className="col-md-6">
                        <h5>Stay connected</h5>
                        <p>Join community to be updated by the latest trends </p>
                        <Form.Control
                            type="text"
                            name="search"
                            placeholder="Your email address"
                            id="search"
                        />
                            <Button>Sign up</Button>
                    </div>
                    <div className="col-md-6">
                        
                        <ul>
                            <li><span>Join the community</span></li>
                            <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                            <path d="M3.35254 13.0443C5.23672 13.1767 6.87876 12.7189 8.39121 11.5583C7.61938 11.4656 6.94686 11.2641 6.37934 10.8148C5.82695 10.3741 5.4032 9.8245 5.17619 9.07348H6.57608C6.58365 9.04226 6.59121 9.01105 6.59878 8.97227C5.82695 8.74809 5.16862 8.34515 4.67676 7.7029C4.18491 7.06917 3.94371 6.33327 3.92006 5.52739C4.41192 5.66738 4.88107 5.80642 5.35779 5.9379C5.37293 5.90669 5.39563 5.87642 5.41076 5.8452C4.72973 5.272 4.23788 4.58341 4.05722 3.69334C3.87073 2.82523 4.00196 1.91902 4.42705 1.13948C6.3188 3.39917 8.69389 4.6534 11.6138 4.86244C11.523 4.12655 11.5154 3.44552 11.7652 2.77962C12.6278 0.449933 15.5326 -0.293525 17.3401 1.37878C17.5444 1.56417 17.7185 1.59539 17.9379 1.51783C18.5357 1.30122 19.125 1.06948 19.7379 0.836796C19.5034 1.60295 18.9964 2.16859 18.3995 2.71814C18.9812 2.56302 19.5639 2.40033 20.139 2.2452C20.1617 2.26885 20.1844 2.28398 20.2071 2.30763C19.7682 2.77206 19.3444 3.26013 18.8687 3.6782C18.6114 3.90238 18.5282 4.11141 18.513 4.45193C18.3768 8.24489 16.8558 11.3095 13.6181 13.2382C10.3199 15.1961 6.92416 15.0798 3.58049 13.2145C3.51996 13.1767 3.46604 13.1294 3.35254 13.0443Z" fill="white"/>
                            </svg></a></li>
                            <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                            <path d="M14.1451 1.26074H9.41575C7.23945 1.26074 5.47461 3.02559 5.47461 5.20189V9.93126C5.47461 12.1075 7.23945 13.8724 9.41575 13.8724H14.1451C16.3214 13.8724 18.0863 12.1075 18.0863 9.93126V5.20189C18.0863 3.02559 16.3214 1.26074 14.1451 1.26074ZM16.9039 9.93126C16.9039 11.4525 15.6664 12.6901 14.1451 12.6901H9.41575C7.89447 12.6901 6.65695 11.4525 6.65695 9.93126V5.20189C6.65695 3.68061 7.89447 2.44309 9.41575 2.44309H14.1451C15.6664 2.44309 16.9039 3.68061 16.9039 5.20189V9.93126Z" fill="white"/>
                            <path d="M11.7799 4.41406C10.0387 4.41406 8.62695 5.82578 8.62695 7.56698C8.62695 9.30821 10.0387 10.7199 11.7799 10.7199C13.5211 10.7199 14.9328 9.30821 14.9328 7.56698C14.9328 5.82578 13.5211 4.41406 11.7799 4.41406ZM11.7799 9.53755C10.6937 9.53755 9.8093 8.65316 9.8093 7.56698C9.8093 6.48004 10.6937 5.59641 11.7799 5.59641C12.866 5.59641 13.7504 6.48004 13.7504 7.56698C13.7504 8.65316 12.866 9.53755 11.7799 9.53755Z" fill="white"/>
                            <path d="M15.1691 4.59806C15.4012 4.59806 15.5893 4.40997 15.5893 4.17794C15.5893 3.94591 15.4012 3.75781 15.1691 3.75781C14.9371 3.75781 14.749 3.94591 14.749 4.17794C14.749 4.40997 14.9371 4.59806 15.1691 4.59806Z" fill="white"/>
                            </svg></a></li>
                            <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                            <path d="M9.04669 0.472801C8.78184 0.474692 6.612 0.536174 4.32109 2.24442C4.32109 2.24442 1.87695 6.65693 1.87695 12.0891C1.87695 12.0891 3.30239 14.5417 7.05373 14.6609C7.05373 14.6609 7.68179 13.9118 8.19067 13.2639C6.03597 12.6159 5.22062 11.27 5.22062 11.27C5.22062 11.27 5.39088 11.3891 5.69545 11.5603C5.71248 11.5603 5.72856 11.5783 5.76166 11.5944C5.81368 11.6275 5.86382 11.6455 5.91489 11.6795C6.33865 11.9179 6.7624 12.1052 7.15399 12.2575C7.85015 12.5478 8.68158 12.8023 9.65015 12.9896C10.9233 13.2279 12.4168 13.3131 14.0447 13.0075C14.8888 12.8504 15.714 12.6051 16.5068 12.2754C17.1933 12.0162 17.8478 11.6788 18.4572 11.27C18.4572 11.27 17.6097 12.649 15.3859 13.2799C15.8948 13.9099 16.5068 14.6411 16.5068 14.6411C20.2572 14.5219 21.6836 12.0683 21.6836 12.0872C21.6836 6.6522 19.2394 2.24159 19.2394 2.24159C16.8123 0.421723 14.4864 0.472801 14.4864 0.472801L14.249 0.745213C17.1339 1.61353 18.4752 2.89235 18.4752 2.89235C16.8921 2.03028 15.1581 1.48083 13.3674 1.27396C12.227 1.14702 11.0754 1.15845 9.9377 1.30801C9.83649 1.30801 9.75136 1.32598 9.64826 1.34111C9.05425 1.40922 7.61179 1.61353 5.79571 2.41468C5.1686 2.68709 4.79403 2.89235 4.79403 2.89235C4.79403 2.89235 6.18825 1.54637 9.24248 0.678055L9.07222 0.472801H9.04669ZM8.61442 6.75814C9.5811 6.75814 10.3633 7.5924 10.3454 8.63192C10.3454 9.67238 9.5811 10.5066 8.61442 10.5066C7.66382 10.5066 6.88347 9.67143 6.88347 8.63192C6.88347 7.5924 7.64584 6.75814 8.61442 6.75814ZM14.8099 6.75814C15.7596 6.75814 16.5409 7.5924 16.5409 8.63192C16.5409 9.67238 15.7756 10.5066 14.8099 10.5066C13.8584 10.5066 13.0789 9.67143 13.0789 8.63192C13.0789 7.5924 13.8413 6.75814 14.8099 6.75814Z" fill="white"/>
                            </svg></a></li>
                            <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                            <path d="M17.6601 5.86961C17.1821 5.86961 16.7615 6.07076 16.4519 6.38416C15.3137 5.58458 13.7788 5.07003 12.0781 5.01517L12.9616 0.97565L15.7746 1.61632C15.7746 2.31501 16.3358 2.88569 17.0244 2.88569C17.7263 2.88569 18.2906 2.29988 18.2906 1.60056C18.2906 0.901242 17.7301 0.31543 17.0251 0.31543C16.5338 0.31543 16.1101 0.616218 15.8988 1.02736L12.792 0.328672C12.6356 0.286423 12.4823 0.399928 12.4407 0.558835L11.4709 5.01201C9.78344 5.08327 8.265 5.5972 7.12301 6.39741C6.81339 6.07076 6.3764 5.86961 5.89842 5.86961C4.12522 5.86961 3.54445 8.28411 5.1682 9.10954C5.11082 9.36493 5.08496 9.63734 5.08496 9.90912C5.08496 12.6213 8.096 14.8188 11.7931 14.8188C15.506 14.8188 18.517 12.6213 18.517 9.90912C18.517 9.63734 18.4886 9.35232 18.4186 9.0963C20.0096 8.26772 19.4238 5.87087 17.6601 5.86961ZM7.74476 9.28106C7.74476 8.56913 8.30598 7.99593 9.01098 7.99593C9.69957 7.99593 10.2614 8.56535 10.2614 9.28106C10.2614 9.98038 9.7002 10.5504 9.01098 10.5504C8.30914 10.5536 7.74476 9.98038 7.74476 9.28106ZM14.5797 12.3066C13.4188 13.4845 10.1429 13.4845 8.98134 12.3066C8.85333 12.1931 8.85333 11.9926 8.98134 11.8633C9.09295 11.7498 9.29095 11.7498 9.40257 11.8633C10.2892 12.7858 13.2302 12.8016 14.1553 11.8633C14.2669 11.7498 14.4649 11.7498 14.5765 11.8633C14.707 11.9932 14.707 12.1937 14.5797 12.3066ZM14.5538 10.5529C13.8652 10.5529 13.304 9.98353 13.304 9.28485C13.304 8.57292 13.8652 7.99972 14.5538 7.99972C15.2556 7.99972 15.82 8.56913 15.82 9.28485C15.8169 9.98038 15.2556 10.5529 14.5538 10.5529Z" fill="white"/>
                            </svg></a></li>
                            <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                            <path d="M11.7756 2.80273C11.7756 2.80273 6.95801 2.80274 5.74886 3.11693C5.10143 3.29783 4.56834 3.83094 4.38744 4.48788C4.07325 5.69703 4.07324 8.20108 4.07324 8.20108C4.07324 8.20108 4.07325 10.7147 4.38744 11.9048C4.56834 12.5617 5.09192 13.0852 5.74886 13.2662C6.96753 13.5899 11.7756 13.5899 11.7756 13.5899C11.7756 13.5899 16.6027 13.5899 17.8119 13.2757C18.4688 13.0948 18.9924 12.5808 19.1637 11.9143C19.4874 10.7147 19.4874 8.21061 19.4874 8.21061C19.4874 8.21061 19.497 5.69703 19.1637 4.48788C18.9924 3.83094 18.4688 3.30736 17.8119 3.13598C16.6027 2.80275 11.7756 2.80273 11.7756 2.80273ZM10.2427 5.88755L14.2511 8.20108L10.2427 10.5051V5.88755Z" fill="white"/>
                            </svg></a></li>
                            <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                            <path d="M14.7384 0.000152588C14.9881 2.14788 16.1869 3.42835 18.271 3.56457V5.98019C17.0632 6.09825 16.0052 5.70321 14.7747 4.95855V9.4765C14.7747 15.2159 8.51771 17.0094 6.00219 12.8956C4.38572 10.2484 5.37558 5.60332 10.561 5.41715V7.96446C10.166 8.02803 9.74369 8.12792 9.35773 8.2596C8.20441 8.6501 7.55055 9.38114 7.73218 10.6707C8.08181 13.1408 12.6134 13.8719 12.2365 9.04514V0.00469324H14.7384V0.000152588Z" fill="white"/>
                            </svg></a></li>
                        </ul>
                    </div>
                </div>
                <div className="row footerMenu mt-5">
                    <div className="col-md-5">
                        <img src={Logo} alt="Logo" />
                        <p>Crypto Media is a cutting-edge NFT marketplace that revolutionizes the way digital media is bought, sold, and owned. With its secure blockchain technology, artists and creators can tokenize their unique works, allowing collectors to own one-of-a-kind digital assets. The platform provides a seamless and transparent experience, empowering artists and collectors to engage in a vibrant and decentralized marketplace for NFTs.</p>
                    </div>
                    <div className="col">
                        <h4>My Account</h4>
                        <ul>
                            <li><a href="#">Profile</a></li>
                            <li><a href="#">Favorites</a></li>
                            <li><a href="#">Watchlist</a></li>
                            <li><a href="#">My collections</a></li>
                            <li><a href="#">Create</a></li>
                            <li><a href="#">Settings</a></li>
                        </ul>
                    </div>
                    <div className="col">
                        <h4>Marketplace</h4>
                        <ul>
                            <li><a href="#">All NFTs</a></li>
                            <li><a href="#">Art</a></li>
                            <li><a href="#">Gaming</a></li>
                            <li><a href="#">Photography</a></li>
                            <li><a href="#">Music</a></li>
                        </ul>
                    </div>
                  
                    <div className="col">
                        <h4>Resources</h4>
                        <ul>
                            <li><a href="#">Help center</a></li>
                            <li><a href="#">Community standards</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Terms of Service</a></li>
                        </ul>
                    </div>
                    <div className="col">
                        <h4>Company</h4>
                        <ul>
                            <li><a href="#">About</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row copyright mt-5">
                    <div className="col-md-12 text-center">
                        <p>© 2023 Crypto Media</p>
                    </div>
                </div>
            </div>
        </footer>
);
};

export default Footer;