import React, { useState } from "react";


const CreateCell = () => {
    return (
    <>
   {/*--------------- sell---------- */}
   <section className="sell_now">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3>Create and Sell now</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="sell_block">
              <img src={require("../../assets/images/ion_wallet-outline.png")} alt="Banner Background"
                        className="img-fluid"
                      />
                  <span>Set up your wallet</span>
                  <p>Lorem ipsum dolor sit amet consectetur. Volutpat erat accumsan justo rhoncus. Sem lectus adipiscing dolor massa pharetra quam mi sed leo.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="sell_block">
              <img src={require("../../assets/images/nft_icon.png")} alt="Banner Background"
                        className="img-fluid"
                      />
                  <span>Add your NFT's</span>
                  <p>Lorem ipsum dolor sit amet consectetur. Volutpat erat accumsan justo rhoncus. Sem lectus adipiscing dolor massa pharetra quam mi sed leo.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="sell_block">
              <img src={require("../../assets/images/hand.png")} alt="Banner Background"
                        className="img-fluid"
                      />
                  <span>Sell your NFT's</span>
                  <p>Lorem ipsum dolor sit amet consectetur. Volutpat erat accumsan justo rhoncus. Sem lectus adipiscing dolor massa pharetra quam mi sed leo.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

    };
export default CreateCell;
