import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Profile from "./components/ProfilePage/profile";
import LandingPage from "./pages/home/LandingPage";
import Explore from "./pages/ExplorePages/explore";
import './assets/fonts/stylesheet.css'
import Collections from "./pages/collections/collections";
import CollectionsDetails from "./pages/collectionsdetails/collectionsDetails";
import Createnft from "./components/NFT/createnft";
import Stats from "./pages/stats/stats";
import MintingDetails from "./pages/mintingdetailpage/mintingdetails";
import { requireAuth } from './components/middlleware/middleware';

function App() {
  
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/explore" element={<Explore />} onEnter={requireAuth}/>
          <Route path="/collections" element={<Collections />} />
          <Route path="/collectionsdetails" element={<CollectionsDetails />} />
          <Route path="/profile/:tab?" element={<Profile />} />
          <Route path="/createnft" element={<Createnft />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/mintcollectionsdetails" element={<MintingDetails/>} />
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
