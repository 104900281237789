import React, { useState } from 'react';
import "./stats.css";
import Table from 'react-bootstrap/Table';




const initialData = [
    {
        id: 1,
        collectionName: 'Trending Collection 1',
        volume: '0.25 ETH',
        change: '+107%',
        floorPrice: '0.01 ETH',
        sales: 20,
        uniqueOwners: '20% (2,036 owners)',
        itemsListed: '10% (1,044 of 9,999)',
        image: require("../../assets/images/collection1.png"),
      },
      // Initial data for Top
      {
        id: 2,
        collectionName: 'Top Collection 2',
        volume: '0.35 ETH',
        change: '+50%',
        floorPrice: '0.02 ETH',
        sales: 30,
        uniqueOwners: '30% (3,036 owners)',
        itemsListed: '20% (2,044 of 9,999)',
        image: require("../../assets/images/collection2.png"),
      },
      // Initial data for Watchlist
      {
        id: 3,
        collectionName: 'Watchlist Collection 3',
        volume: '0.15 ETH',
        change: '-10%',
        floorPrice: '0.005 ETH',
        sales: 10,
        uniqueOwners: '10% (1,036 owners)',
        itemsListed: '5% (544 of 9,999)',
        image: require("../../assets/images/collection3.png"),
      },
    // Add more data for other rows as needed
  ];

function StateTable() {
  const [activeLink, setActiveLink] = useState('Trending');
const [data, setData] = useState(initialData);

 
const handleLinkClick = (link) => {
    setActiveLink(link);

    
    switch (link) {
      case 'Trending':
        setData(initialData); 
        break;
      case 'Top':
        setData(initialData); 
        break;
      case 'Watchlist':
        setData(initialData); 
        break;
      default:
        setData([]); 
    }
  };



  return (
    <section className="state-table">
      <div className="container">
        <div className="row">
          <div className="col-md-12 heading text-start">
            <h2>Collection stats</h2>
            <ul>
              <li>
                <a
                  href="#"
                  className={activeLink === 'Trending' ? 'active' : ''}
                  onClick={() => handleLinkClick('Trending')}
                >
                  Trending
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={activeLink === 'Top' ? 'active' : ''}
                  onClick={() => handleLinkClick('Top')}
                >
                  Top
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={activeLink === 'Watchlist' ? 'active' : ''}
                  onClick={() => handleLinkClick('Watchlist')}
                >
                  Watchlist
                </a>
              </li>
            </ul>
            <div className='stats_table'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Collection</th>
                        <th>Volume</th>
                        <th>% Change</th>
                        <th>Floor price</th>
                        <th>Sales</th>
                        <th>% Unique owners</th>
                        <th>% Items listed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>
                            <div className='d-flex align-items-center'>
                                <div className='table_img'>
                                <img src={item.image} alt='Collection' />
                                </div>
                                <div className='user_name'>
                                <p>{item.collectionName} <img src={require("../../assets/images/verified-icon.png")} alt='Verified' /></p>
                                </div>
                            </div>
                            </td>
                            <td>{item.volume}</td>
                            <td><span className={item.change.includes('+') ? 'green' : 'red'}>{item.change}</span></td>
                            <td>{item.floorPrice}</td>
                            <td>{item.sales}</td>
                            <td>{item.uniqueOwners}</td>
                            <td>{item.itemsListed}</td>
                            <td><img src={require("../../assets/images/starVector.png")} alt='Star' /></td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StateTable;
